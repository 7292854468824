var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    {
      class: _vm.$vuetify.theme.dark ? undefined : "grey lighten-3",
      attrs: { id: "pages" }
    },
    [
      _c(
        "v-img",
        {
          class: {
            "v-image--sm": this.$vuetify.breakpoint.smAndDown,
            "v-image--md": this.$vuetify.breakpoint.mdAndDown
          },
          attrs: {
            src: require("@/assets/" + (_vm.src || "clint-mckoy.jpg")),
            gradient: "to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)",
            "min-height": "100%"
          }
        },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }